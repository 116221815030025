export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_CENTRAL_LOGS_SUCCESS":
    case "GET_CENTRAL_LOGS_ERROR":
      return {
        ...state,
        GetCentralLogsResponse: action.updatePayload,
      };
    case "GET_CENTRAL_LOGS_FILTERS_SUCCESS":
    case "GET_CENTRAL_LOGS_FILTERS_ERROR":
      return {
        ...state,
        GetCentralLogsFiltersResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ICENTRAL_LOG":
      return [];
    default:
      return state;
  }
};
