export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_CLIENT_LIST_SUCCESS":
        case "GET_CLIENT_LIST_ERROR":
            return {
                ...state,
                GetClientListResponse: action.updatePayload,
            };
        case "GET_CLIENT_MASTER_DATA_SUCCESS":
        case "GET_CLIENT_MASTER_DATA_ERROR":
            return {
                ...state,
                GetClientMasterDataResponse: action.updatePayload,
            };
        case "ADD_CLIENT_SUCCESS":
        case "ADD_CLIENT_ERROR":
            return {
                ...state,
                AddClientResponse: action.updatePayload,
            };
        case "UPDATE_CLIENT_SUCCESS":
        case "UPDATE_CLIENT_ERROR":
            return {
                ...state,
                UpdateClientResponse: action.updatePayload,
            };
        case "ADD_CLIENT_ADDRESS_SUCCESS":
        case "ADD_CLIENT_ADDRESS_ERROR":
            return {
                ...state,
                AddClientAddressResponse: action.updatePayload,
            };
        case "GET_CLIENT_ADDRESS_LIST_SUCCESS":
        case "GET_CLIENT_ADDRESS_LIST_ERROR":
            return {
                ...state,
                GetClientAddressListResponse: action.updatePayload,
            };
        case "GET_CLIENT_ACCOUNT_DETAIL_SUCCESS":
        case "GET_CLIENT_ACCOUNT_DETAIL_ERROR":
            return {
                ...state,
                GetClientAccountDetailResponse: action.updatePayload,
            };
        case "ADD_PREP_CENTER_SUCCESS":
        case "ADD_PREP_CENTER_ERROR":
            return {
                ...state,
                AddPrepCenterResponse: action.updatePayload,
            };
        case "UPDATE_PREP_CENTER_SUCCESS":
        case "UPDATE_PREP_CENTER_ERROR":
            return {
                ...state,
                UpdatePrepCenterResponse: action.updatePayload,
            };
        case "FAKE_ACTION_CLIENT":
            return [];
        default:
            return state;
    }
};
