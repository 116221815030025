export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_SOURCE_LIST_SUCCESS":
        case "GET_SOURCE_LIST_ERROR":
            return {
                ...state,
                GetSourcesListResponse: action.updatePayload,
            };
        case "GET_SOURCE_DETAILS_SUCCESS":
        case "GET_SOURCE_DETAILS_ERROR":
            return {
                ...state,
                GetSourcesDetailsResponse: action.updatePayload,
            };
        case "ADD_SOURCE_DATA_SUCCESS":
        case "ADD_SOURCE_DATA_ERROR":
            return {
                ...state,
                AddSourcesDataResponse: action.updatePayload,
            };
        case "UPDATE_SOURCE_DATA_SUCCESS":
        case "UPDATE_SOURCE_DATA_ERROR":
            return {
                ...state,
                UpdateSourcesDataResponse: action.updatePayload,
            };
        case "FAKE_ACTION_SOURCES":
            return [];
        default:
            return state;
    }
};
