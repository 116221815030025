import React, { useState, useEffect, useContext, useMemo } from "react";
import { adminMenus, staffMenus, userMenus } from "./lib/menus";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../../commonContext";
import { Select, message } from "antd";
import { EndPoint } from "../../../config";

export default function (props) {
  const { hide, screenSize, deviceType } = props;
  const history = useHistory();
  const location = useLocation();
  const contextVar = useContext(GlobalContext);
  const user_ = contextVar?.data?.profile;
  const [current, setCurrent] = useState("dashboard");
  const [subMenuOpen, setSubMenuOpen] = useState("");
  const [selectedClient, setSelectedClient] = useState("");

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const splitLoca = location.pathname.split("/");
    if (splitLoca[1]) {
      if (splitLoca.length > 2) {
        if (
          location.pathname.includes("view") ||
          location.pathname.includes("edit") ||
          location.pathname.includes("add")
        ) {
          if (
            location.pathname.includes("inventory-order") ||
            location.pathname.includes("inventory-items") ||
            location.pathname.includes("location") ||
            location.pathname.includes("sources")
          ) {
            setCurrent(splitLoca[1]);
            setSubMenuOpen("");
          } else {
            setCurrent(splitLoca[2]);
            setSubMenuOpen(splitLoca[1]);
          }
        } else {
          setCurrent(splitLoca[2]);
          setSubMenuOpen(splitLoca[1]);
        }
      } else {
        setCurrent(splitLoca[1]);
        setSubMenuOpen("");
      }
    } else {
      setCurrent("dashboard");
      setSubMenuOpen("");
    }
  }, [location]);

  let menu_style = {
    height: "calc(100% - 430px)",
    maxHeight: "650px",
    overflow: "auto",
    transition: "width 0.4s, 0.4s",
    animation: ".5s linear",
    display: "flex",
    justifyContent: "center",
  };
  if (deviceType === "Desktop") {
    menu_style = {
      height: "calc(100% - 220px)",
      maxHeight: "650px",
      overflow: "auto",
      transition: "width 0.4s, 0.4s",
      animation: ".5s linear",
      display: "flex",
      justifyContent: "center",
    };
  }
  if (deviceType === "Tablet") {
    menu_style = {
      ...menu_style,
      height: "calc(100% - 230px)",
      maxHeight: "450px",
    };
  }
  if (deviceType === "Laptop") {
    menu_style = {
      ...menu_style,
      height: "calc(100% - 220px)",
      maxHeight: "450px",
    };
  }
  if (deviceType === "largeLaptop") {
    menu_style = {
      ...menu_style,
      height: "calc(100% - 220px)",
      maxHeight: "450px",
    };
  }

  if (localStorage.getItem("userType") == 2 && deviceType !== "Desktop") {
    menu_style = {
      ...menu_style,
      minHeight: "300px",
    };
  }

  const mainMenuList =
    localStorage.getItem("userType") == 1
      ? adminMenus
      : localStorage.getItem("userType") == 3
      ? staffMenus
      : userMenus;

  const handleSwitchUser = async (user_id) => {
    message.destroy();
    message.loading("Switching...", 0);
    setSelectedClient(user_id);
    axios({
      url: `${EndPoint}api/v1/switch-user`,
      method: "POST",
      data: { id: user_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        message.destroy();
        if (res?.data?.code === 200 && res?.data?.status) {
          history.push("/");
          // setTimeout(() => {
          //     history.push('/')
          // }, 300);
          let SwitchUserRes = res?.data?.data;
          let adminToken = localStorage.getItem("token");
          let adminData = localStorage.getItem("user");
          const data = {
            token: adminToken,
            data: adminData,
          };
          localStorage.setItem("adminData", JSON.stringify(data));
          let all_client = localStorage.getItem("client-list");
          all_client = all_client ? JSON.parse(all_client) : [];
          let selected_client_data = all_client.filter((e) => e.id === user_id);
          selected_client_data =
            selected_client_data.length > 0 ? selected_client_data[0] : "0";
          const userData = {
            ...SwitchUserRes?.user_data,
            client_account_id: selected_client_data?.client_account_id,
          };
          localStorage.setItem(
            "user",
            JSON.stringify(userData ? userData : SwitchUserRes?.user_data)
          );
          localStorage.setItem("userType", SwitchUserRes?.user_data?.user_type);
          localStorage.setItem(
            "token",
            SwitchUserRes?.auth_token_data?.access_token
          );
          contextVar?.updateCommonGlobalVal(
            "profile",
            userData ? userData : SwitchUserRes?.user_data
          );
          message.success("User switch successfully");
        }
      })
      .catch((error) => {
        if (error.response.data.status === 401) {
          if (error.response.data.name === "Unauthorized") {
            localStorage.clear();
            window.location.href = "/login";
          } else if (error.response.data.refresh_expire) {
            return error.response;
          }
        } else {
          return error.response;
        }
      });
  };

  const ClientOptionList = useMemo(() => {
    let all_client = localStorage.getItem("client-list");
    all_client = all_client ? JSON.parse(all_client) : [];
    if (all_client.length > 0) {
      setSelectedClient(user_?.id);
      all_client = all_client.reverse();
      return all_client.map((option) => ({
        label: `${option?.company_name ? `${option?.company_name} -` : ""} ${
          option?.user_type ? `${option?.user_type} -` : ""
        } ${option?.name ? `${option?.name}` : ""}`,
        value: option?.id,
      }));
    } else {
      return [];
    }
  }, [localStorage.getItem("client-list")]);

  const handleSidebarToggle = () => {
    const is_on = document.body.getAttribute("data-kt-app-sidebar-minimize");
    if (is_on === "off") {
      document.body.setAttribute("data-kt-app-sidebar-minimize", "on");
    } else {
      document.body.setAttribute("data-kt-app-sidebar-minimize", "off");
    }
  };

  return (
    <div
      id="kt_app_sidebar"
      className={`app-sidebar flex-column ${
        screenSize && !hide ? "drawer drawer-start drawer-on" : ""
      } `}
      style={{ overflow: "hidden" }}
      data-kt-drawer="true"
      data-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="250px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
    >
      <>
        <div
          className="app-sidebar-header d-flex flex-stack d-none d-lg-flex pt-5 pb-4 justify-content-center border-bottom border-bottom-dashed border-gray-300"
          id="kt_app_sidebar_header"
        >
          <Link to="/" className="app-sidebar-logo">
            <img
              alt="Logo"
              src="/assets/media/logo-bookprep.png"
              className="h-60px d-none d-sm-inline app-sidebar-logo-default theme-light-show shimmer"
            />
          </Link>
        </div>
        <div className="mt-3 mx-5 select-marketplace">
          {localStorage.getItem("userType") == 1 && (
            <div className="app-navbar-item ms-1 ms-md-3">
              <Select
                size="large"
                showSearch
                value={selectedClient}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  width: "100%",
                }}
                className="fs-7 br-40 header-client"
                onChange={(value) => {
                  handleSwitchUser(value);
                }}
                options={ClientOptionList}
              />
            </div>
          )}
        </div>
        <div
          className="app-sidebar-navs flex-column-fluid pb-6"
          id="kt_app_sidebar_navs"
        >
          <div className="app-navbar-item ms-5 pt-2">
            <div
              id="kt_app_sidebar_toggle"
              onClick={() => {
                handleSidebarToggle();
                setToggle(!toggle);
              }}
              className={`app-sidebar-toggle btn btn-sm btn-icon bg-primary btn-color-light d-lg-flex rotate w-35px h-35px w-md-40px h-md-40px d-none d-lg-block ${
                toggle ? "active" : ""
              }`}
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="app-sidebar-minimize"
            >
              <i className="ki-outline ki-text-align-right rotate-180 fs-1" />
            </div>
          </div>
          <div
            style={menu_style}
            id="kt_app_sidebar_navs_wrappers"
            className="app-sidebar-wrapper hover-scroll-y my-2"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_app_sidebar_header"
            data-kt-scroll-wrappers="#kt_app_sidebar_navs"
            data-kt-scroll-offset="5px"
          >
            <div
              id="#kt_app_sidebar_menu"
              data-kt-menu="true"
              data-kt-menu-expand="false"
              className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary"
            >
              <div className="menu-item mb-2">
                <div className="menu-heading text-uppercase fs-7 fw-bold">
                  Pages
                </div>
                <div className="-top my-3" />
              </div>
              {mainMenuList.map((menu) => {
                if (menu?.subMenu.length > 0) {
                  return (
                    <div
                      onClick={() =>
                        setSubMenuOpen((prev) =>
                          prev === menu?.id ? "" : menu?.id
                        )
                      }
                      className={`menu-item menu-accordion ${
                        subMenuOpen === menu?.id ? "hover show" : ""
                      }`}
                    >
                      <span className="menu-link">
                        <span className="menu-icon">{menu?.icon}</span>
                        <span className="menu-title">{menu?.title}</span>
                        <span className="menu-arrow" />
                      </span>
                      <div
                        className={`menu-sub menu-sub-accordion ${
                          subMenuOpen === menu?.id ? "show" : ""
                        }`}
                      >
                        {menu?.subMenu.map((sub_menu) => {
                          return (
                            <div className="menu-item">
                              <Link
                                to={sub_menu?.link}
                                className={`menu-link ${
                                  current === sub_menu?.id ? "active" : ""
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  {sub_menu?.title}
                                </span>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="menu-item">
                      <Link
                        to={menu?.link}
                        className={`menu-link ${
                          current === menu?.id ? "active" : ""
                        }`}
                      >
                        <span className="menu-icon">{menu?.icon}</span>
                        <span className="menu-title">{menu?.title}</span>
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div
            className="text-center pc-user-card"
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              right: "30px",
            }}
          >
            <div
              className="m-4 p-3 py-5 rounded"
              style={{
                background:
                  "url(/assets/media/svg/shapes/wave-bg-dark.svg) #f2f2f2",
              }}
            >
              <div>
                <div
                  className="cursor-pointer symbol symbol-circle symbol-45px symbol-md-65px"
                  style={{ border: "1px solid #f05423", padding: "5px" }}
                >
                  <img src={user_?.photo || ""} alt="user" />
                </div>
              </div>
              <div>
                <div className="d-flex flex-column mt-3">
                  <div className="fw-bold d-flex align-items-center fs-5 justify-content-center text-primary">
                    {user_?.name || ""}
                  </div>
                  <span className="fw-semibold text-muted fs-7 text-gray-700">
                    {user_?.email || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
