export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_LOCATION_LIST_SUCCESS":
        case "GET_LOCATION_LIST_ERROR":
            return {
                ...state,
                GetLocationListResponse: action.updatePayload,
            };
        case "GET_LOCATION_DETAILS_SUCCESS":
        case "GET_LOCATION_DETAILS_ERROR":
            return {
                ...state,
                GetLocationDetailsResponse: action.updatePayload,
            };
        case "GET_LOCATION_DROPDOWN_DATA_SUCCESS":
        case "GET_LOCATION_DROPDOWN_DATA_ERROR":
            return {
                ...state,
                GetLocationDropdownDataResponse: action.updatePayload,
            };
        case "ADD_LOCATION_SUCCESS":
        case "ADD_LOCATION_ERROR":
            return {
                ...state,
                AddLocationResponse: action.updatePayload,
            };
        case "UPDATE_LOCATION_SUCCESS":
        case "UPDATE_LOCATION_ERROR":
            return {
                ...state,
                UpdateLocationResponse: action.updatePayload,
            };
        // case "DELETE_LOCATION_SUCCESS":
        // case "DELETE_LOCATION_ERROR":
        //     return {
        //         ...state,
        //         DeleteLocationResponse: action.updatePayload,
        //     };
        case "FAKE_ACTION_LOCATION":
            return [];
        default:
            return state;
    }
};
