import React, { useState, createContext } from "react";
export const GlobalContext = createContext();

const GlobalCommonContextProvider = (props) => {
    const [commonGlobalVal, setCommonGlobalVal] = useState({
        profile: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || {}) : {}
    });

    return (
        <GlobalContext.Provider
            value={{
                data: { ...commonGlobalVal },
                updateCommonGlobalVal: (key, value) => {
                    setCommonGlobalVal({ ...commonGlobalVal, [key]: value });
                },
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalCommonContextProvider;