export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_CRON_MANAGER_LIST_SUCCESS":
        case "GET_CRON_MANAGER_LIST_ERROR":
            return {
                ...state,
                GetCronManagerListResponse: action.updatePayload,
            };
        case "GET_CRON_MANAGER_DROPDOWN_SUCCESS":
        case "GET_CRON_MANAGER_DROPDOWN_ERROR":
            return {
                ...state,
                GetCronManagerDropdownResponse: action.updatePayload,
            };
        case "GET_CRON_MANAGER_DETAILS_SUCCESS":
        case "GET_CRON_MANAGER_DETAILS_ERROR":
            return {
                ...state,
                GetCronManagerDetailsResponse: action.updatePayload,
            };
        case "MANUAL_CRON_EXECUTION_SUCCESS":
        case "MANUAL_CRON_EXECUTION_ERROR":
            return {
                ...state,
                ManualCronExecutionResponse: action.updatePayload,
            };
        case "GET_CRON_LOGS_SUCCESS":
        case "GET_CRON_LOGS_ERROR":
            return {
                ...state,
                GetCronLogsResponse: action.updatePayload,
            };
        case "ADD_CRON_MANAGER_SUCCESS":
        case "ADD_CRON_MANAGER_ERROR":
            return {
                ...state,
                AddCronManagerResponse: action.updatePayload,
            };
        case "UPDATE_CRON_MANAGER_SUCCESS":
        case "UPDATE_CRON_MANAGER_ERROR":
            return {
                ...state,
                UpdateCronManagerResponse: action.updatePayload,
            };
        case "FAKE_ACTION_CRON_MANAGER_LIST":
            return [];
        default:
            return state;
    }
};
