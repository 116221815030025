import React, { useEffect, useState, useContext, useMemo } from "react";
import { Dropdown, Select, message } from "antd";
import axios from "axios";
import Gleap from 'gleap';
// import Icons from "../../../components/icons";
import { EndPoint } from '../../../config'
import { useLocation, Link, useHistory } from "react-router-dom";
import Wrapper from "./style";
import { GlobalContext } from "../../../commonContext";
import { nameObject } from "../../../core/lib";

export default function (props) {
    const { hideSidebar, hide } = props;
    const contextVar = useContext(GlobalContext);
    const user_ = contextVar?.data?.profile;
    const location = useLocation();
    const history = useHistory();
    const [toggle, setToggle] = useState(false)
    const [selectedClient, setSelectedClient] = useState('')

    const [current, setCurrent] = useState({
        name: "",
    });

    // const [themes, setThemes] = useState(localStorage.getItem("data-theme"));

    // const themeChange = (type) => {
    //     var defaultThemeMode = type;
    //     setThemes(type);
    //     localStorage.setItem("data-theme", defaultThemeMode);
    //     var themeMode;
    //     if (document.documentElement) {
    //         if (document.documentElement.hasAttribute("data-theme-mode")) {
    //             themeMode = document.documentElement.getAttribute("data-theme-mode");
    //         } else {
    //             if (localStorage.getItem("data-theme") !== null) {
    //                 themeMode = localStorage.getItem("data-theme");
    //             } else {
    //                 themeMode = defaultThemeMode;
    //             }
    //         }
    //         document.documentElement.setAttribute("data-theme", themeMode);
    //         window.location.reload()
    //     }
    // };

    useEffect(() => {
        if (nameObject[location.pathname]) {
            setCurrent(nameObject[location.pathname]);
        } else {
            if (
                Object.keys(nameObject).findIndex((d) =>
                    d.includes("/" + location.pathname.split("/")[1])
                ) !== -1 ||
                location.pathname.includes("/callback_sp")
            ) {
                let name_;
                if (location.pathname.includes('client-address') || location.pathname.includes('vendor-address') || location.pathname.includes('view') || location.pathname.includes('edit') || location.pathname.includes('sent-to-fba') || location.pathname.includes('details') || location.pathname.includes('add-prep-center') || location.pathname.includes('edit-prep-center') || location.pathname.includes('view-prep-center') || location.pathname.includes('inventory-items/status/add') || location.pathname.includes('operation/prep-batch/add-batch-items')) {
                    let url = location.pathname.split("/")
                    if (location.pathname.includes('cron-manager/edit') || location.pathname.includes('prep-center/edit')) {
                        url = `/${url[1]}/${url[2]}/${url[3]}`
                    } else {
                        url = location.pathname.includes('view') || location.pathname.includes('edit/') || location.pathname.includes('sent-to-fba') || location.pathname.includes('inventory-items/status/add') ? `/${url[1]}/${url[2]}` : `/${url[1]}/${url[2]}/${url[3]}`
                    }
                    name_ = nameObject[url]
                    if (name_?.name === 'Client Prep Center' && name_?.sub_link.split("/").length === 4) {
                        name_.sub_link = `${name_.sub_link}/${location.pathname.split("/")[location.pathname.split("/").length - 1]}`
                    }
                } else {
                    name_ = nameObject["/" + location.pathname.split("/")[1]];
                }
                return setCurrent(
                    name_ == undefined
                        ? {
                            name: "Marketplace Credentials",
                        }
                        : name_
                );
            }
            setCurrent({ name: "" });
        }
    }, [location]);

    const ClientOptionList = useMemo(() => {
        let all_client = localStorage.getItem("client-list")
        all_client = all_client ? JSON.parse(all_client) : []
        if (all_client.length > 0) {
            setSelectedClient(user_?.id)
            all_client = all_client.reverse();
            return all_client.map((option) => ({ label: `${option?.company_name ? `${option?.company_name} -` : ''} ${option?.user_type ? `${option?.user_type} -` : ''} ${option?.name ? `${option?.name}` : ''}`, value: option?.id }))
        } else {
            return []
        }
    }, [localStorage.getItem("client-list")]);

    const dropdown_menu = (
        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px show">
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src={user_?.photo || ''} />
                    </div>
                    <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">{user_?.name || ''}</div>
                        <Link to='/' className="fw-semibold text-muted text-hover-primary fs-7">{user_?.email || ''}</Link>
                    </div>
                </div>
            </div>
            <div className="separator my-2" />
            <div className="menu-item px-5">
                <Link to='/' className="menu-link px-5">My Profile</Link>
            </div>
            <div className="separator my-2" />
            <div className="menu-item px-5 my-1">
                <Link to='/' className="menu-link px-5">Help</Link>
            </div>
            <div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                <Link to='/' className="menu-link px-5">
                    <span className="menu-title position-relative">Language
                        <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                            <img className="w-15px h-15px rounded-1 ms-2" src="/assets/media/flags/united-states.svg" alt="" /></span></span>
                </Link>
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                    <div className="menu-item px-3">
                        <Link to='/' className="menu-link d-flex px-5 active">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/united-states.svg" alt="" />
                            </span>English
                        </Link>
                    </div>
                    <div className="menu-item px-3">
                        <Link to='/' className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/spain.svg" alt="" />
                            </span>Spanish
                        </Link>
                    </div>
                    <div className="menu-item px-3">
                        <Link to='/' className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/germany.svg" alt="" />
                            </span>German
                        </Link>
                    </div>
                    <div className="menu-item px-3">
                        <Link to='/' className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/japan.svg" alt="" />
                            </span>Japanese
                        </Link>
                    </div>
                    <div className="menu-item px-3">
                        <Link to='/' className="menu-link d-flex px-5">
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src="/assets/media/flags/france.svg" alt="" />
                            </span>French
                        </Link>
                    </div>
                </div>
            </div>
            <div className="menu-item px-5 my-1">
                <Link to='/' className="menu-link px-5">Account Settings</Link>
            </div>
            <div className="menu-item px-5">
                <Link
                    onClick={() => {
                        localStorage.clear();
                        Gleap.clearIdentity();
                        contextVar?.updateCommonGlobalVal("profile", {});
                        window.location.assign("/login");
                    }}
                    className="menu-link px-5"
                >Sign Out</Link>
            </div>
        </div>
    )

    // const handleGleapUser = (userData, token) => {
    //     Gleap.identify(`user_${userData?.id}`, {
    //         name: userData?.name,
    //         email: userData?.email,
    //     }, token);
    // }

    const handleSwitchUser = async (user_id) => {
        message.destroy();
        message.loading("Switching...", 0);
        setSelectedClient(user_id)
        axios({
            url: `${EndPoint}api/v1/switch-user`,
            method: "POST",
            data: { id: user_id },
            headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}` }
        })
            .then((res) => {
                message.destroy();
                if (res?.data?.code === 200 && res?.data?.status) {
                    history.push('/')
                    let SwitchUserRes = res?.data?.data
                    let adminToken = localStorage.getItem("token");
                    let adminData = localStorage.getItem("user");
                    const data = {
                        token: adminToken,
                        data: adminData,
                    };
                    localStorage.setItem("adminData", JSON.stringify(data));
                    let all_client = localStorage.getItem("client-list")
                    all_client = all_client ? JSON.parse(all_client) : []
                    let selected_client_data = all_client.filter((e) => e.id === user_id)
                    selected_client_data = selected_client_data.length > 0 ? selected_client_data[0] : '0'
                    const userData = { ...SwitchUserRes?.user_data, client_account_id: selected_client_data?.client_account_id };
                    localStorage.setItem(
                        "user",
                        JSON.stringify(userData ? userData : SwitchUserRes?.user_data)
                    );
                    localStorage.setItem(
                        "userType",
                        SwitchUserRes?.user_data?.user_type
                    );
                    localStorage.setItem("token", SwitchUserRes?.auth_token_data?.access_token);
                    contextVar?.updateCommonGlobalVal(
                        "profile",
                        userData ? userData : SwitchUserRes?.user_data
                    );
                    message.success('User switch successfully');
                }
            })
            .catch((error) => {
                if (error.response.data.status === 401) {
                    if (error.response.data.name === "Unauthorized") {
                        localStorage.clear()
                        window.location.href = '/login'
                    } else if (error.response.data.refresh_expire) {
                        return error.response
                    }
                } else {
                    return error.response
                }
            });
    }

    const handleSidebarToggle = () => {
        const is_on = document.body.getAttribute('data-kt-app-sidebar-minimize')
        if (is_on === 'off') {
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
        } else {
            document.body.setAttribute('data-kt-app-sidebar-minimize', 'off')
        }
    }

    const backToAdmin = () => {
        let adminDataLocal = localStorage.getItem("adminData");
        if (adminDataLocal) {
            message.destroy();
            message.loading('Loading...', 0)
            adminDataLocal = JSON.parse(adminDataLocal);
            localStorage.setItem("user", adminDataLocal.data);
            contextVar?.updateCommonGlobalVal('profile', JSON.parse(adminDataLocal.data));
            localStorage.setItem(
                "userType",
                JSON.parse(adminDataLocal?.data)?.user_type
            );
            localStorage.setItem("token", adminDataLocal.token);
            localStorage.removeItem("adminData");
            setSelectedClient(JSON.parse(adminDataLocal?.data)?.id)
            setTimeout(() => {
                history.push('/')
            }, 300);
        }
    };

    return (
        <Wrapper id="kt_app_header" className="app-header">
            <div className="app-container container-fluid d-flex align-items-stretch flex-stack" id="kt_app_header_container">
                <div className="d-flex align-items-center d-block d-lg-none ms-n3" title="Show sidebar menu">
                    <div onClick={() => hideSidebar()} className="btn btn-icon btn-active-color-primary w-35px h-35px me-2" id="kt_app_sidebar_mobile_toggle">
                        <i className="ki-outline ki-abstract-14 fs-2" />
                    </div>
                    <Link to='/'>
                        <img alt="Logo" src="/assets/media/logo-bookprep.png" className="h-40px" />
                    </Link>
                </div>
                <div className="app-navbar flex-lg-grow-1 w-100" id="kt_app_header_navbar">
                    {/* <div className="app-navbar-item me-5">
                        <div id="kt_app_sidebar_toggle" onClick={() => { handleSidebarToggle(); setToggle(!toggle) }} className={`app-sidebar-toggle btn btn-sm btn-icon bg-primary btn-color-light d-lg-flex rotate w-35px h-35px w-md-40px h-md-40px d-none d-lg-block ${toggle ? 'active' : ''}`} data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
                            <i className="ki-outline ki-text-align-right rotate-180 fs-1" />
                        </div>
                    </div> */}
                    <div className="app-navbar-item ">
                        <div className="page-title gap-4 me-3 mb-5 mb-lg-0" data-kt-swapper={1} data-kt-swapper-mode="{default: 'prepend', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_content_container', lg: '#kt_app_header_wrapper'}">
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 mb-2">
                                <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                                    <Link to='/' className="text-gray-700 text-hover-primary me-1">
                                        <i className="ki-outline ki-home text-gray-700 fs-6" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                                </li>
                                <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                                    {current?.parent ? current?.parent : 'Pages'}
                                </li>
                                <li className="breadcrumb-item">
                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                                </li>
                                {current?.sub_parent ? <>
                                    <li className="breadcrumb-item text-gray-600 fw-bold lh-1">
                                        <Link to={current?.sub_link} className='cursor-pointer'>
                                            {current?.sub_parent}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                                    </li>
                                </> : null}
                                <li className="breadcrumb-item text-gray-500">
                                    {current?.name || ""}
                                </li>
                            </ul>
                            <h1 className="text-gray-900 fw-bolder m-0 fs-2x">
                                {current?.name || ""}
                            </h1>
                        </div>
                    </div>

                    <div className="d-flex ms-auto justify-content-end bg-header-round">
                        {localStorage.getItem("adminData") && <div className="app-navbar-item me-3 ms-md-6">
                            <div
                                onClick={() => backToAdmin()}
                                className="btn  fs-7 btn-primary fw-bold"
                                id="kt_activities_toggle"
                            >
                                back to admin
                            </div>
                        </div>}
                        <div className="app-navbar-item ms-0 ms-md-0">
                            <div id="kt_header_search" className="header-search d-flex align-items-center w-xl-200px" data-kt-search-keypress="true" data-kt-search-min-length={2} data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="true" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-start">
                                <div data-kt-search-element="toggle" className="search-toggle-mobile d-flex d-lg-none align-items-center">
                                    <div className="d-flex">
                                        <i className="ki-outline ki-magnifier fs-1" />
                                    </div>
                                </div>
                                <div className="d-none d-xl-block w-100 position-relative mb-5 mb-lg-0" autoComplete="off" style={{ borderRadius: '30px' }}>
                                    <input type="hidden" />
                                    <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                                    <input
                                        type="text"
                                        className="search-input form-control ps-13 fs-7"
                                        name="search"
                                        placeholder="Search..."
                                        style={{ borderRadius: '40px' }}
                                    />
                                    <span className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
                                        <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
                                    </span>
                                    <span className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                                        <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {localStorage.getItem("userType") == 1 && <div className="app-navbar-item ms-1 ms-md-3">
                            <Select
                                size='large'
                                showSearch
                                value={selectedClient}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                className="w-300px fs-7 br-40 header-client"
                                onChange={(value) => {
                                    handleSwitchUser(value)
                                }}
                                options={ClientOptionList}
                            />
                        </div>}
                        <div className="app-navbar-item ms-1 ms-md-3">
                            <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                <i className="ki-outline ki-calendar fs-1" />
                            </div>
                        </div>
                        <div className="app-navbar-item ms-1 ms-md-3">
                            <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                <i className="ki-outline ki-abstract-26 fs-1" />
                            </div>
                        </div>
                        <div className="app-navbar-item ms-1 ms-md-3">
                            <div className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative" id="kt_drawer_chat_toggle">
                                <i className="ki-outline ki-notification-on fs-1" />
                                <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger w-15px h-15px ms-n4 mt-3">5</span>
                            </div>
                        </div>
                        {/* <div className="d-flex align-items-center d-block d-lg-none" title="Show sidebar menu">
                            <div className="app-navbar-item ms-1 ms-md-3">
                                <div onClick={() => hideSidebar()} className="btn btn-icon btn-active-color-primary w-35px h-35px me-2" id="kt_app_sidebar_mobile_toggle">
                                    <i className="ki-outline ki-abstract-14 fs-2" />
                                </div>
                            </div>
                        </div> */}
                        <div className="app-navbar-item ms-1 ms-md-3" id="kt_header_user_menu_toggle">
                            <Dropdown overlay={dropdown_menu} trigger={['click']}>
                                <div className="cursor-pointer symbol symbol-circle symbol-35px ">
                                    <img src={user_?.photo || ''} alt="user" />
                                </div>
                            </Dropdown>
                        </div>

                    </div>
                </div>
                <div className="app-navbar-separator separator d-none d-lg-flex" />
            </div>
        </Wrapper>
    );
}
