export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "LOGIN_SUCCESS":
        case "LOGIN_ERROR":
            return {
                ...state,
                LoginResponse: action.updatePayload,
            };

        case "FAKE_ACTION_AUTH":
            return [];
        default:
            return state;
    }
};
