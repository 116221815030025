export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_PREP_CENTER_LIST_SUCCESS":
        case "GET_PREP_CENTER_LIST_ERROR":
            return {
                ...state,
                GetPrepCenterListResponse: action.updatePayload,
            };
        // case "GET_PREP_BATCH_DROPDOWN_DATA_SUCCESS":
        // case "GET_PREP_BATCH_DROPDOWN_DATA_ERROR":
        //     return {
        //         ...state,
        //         GetPrepBatchDropdownDataResponse: action.updatePayload,
        //     };
        case "GET_PREP_CENTER_DETAILS_SUCCESS":
        case "GET_PREP_CENTER_DETAILS_ERROR":
            return {
                ...state,
                GetPrepCenterDetailsResponse: action.updatePayload,
            };
        case "ADD_PREP_CENTER_SUCCESS":
        case "ADD_PREP_CENTER_ERROR":
            return {
                ...state,
                AddPrepCenterResponse: action.updatePayload,
            };
        case "UPDATE_PREP_CENTER_SUCCESS":
        case "UPDATE_PREP_CENTER_ERROR":
            return {
                ...state,
                UpdatePrepCenterResponse: action.updatePayload,
            };
        // case "SEARCH_PRODUCT_ACTION_SUCCESS":
        // case "SEARCH_PRODUCT_ACTION_ERROR":
        //     return {
        //         ...state,
        //         SearchProductResponse: action.updatePayload,
        //     };
        case "FAKE_ACTION_PREP_CENTER":
            return [];
        default:
            return state;
    }
};
