import { message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Gleap from 'gleap';
import Wrapper from "./style";
import { initialCall } from '../../../config'
import { GlobalContext } from "../../../commonContext";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    const { loginAction, fakeActionAuth } = props;
    const contextVar = useContext(GlobalContext);
    const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});

    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        email: "admin@ezprep.com",
        password: "admin@ezprep.com",
    });

    useEffect(() => {
        if (LoginRes?.status === true) {
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(LoginRes?.data?.user_data));
            contextVar?.updateCommonGlobalVal("profile", LoginRes?.data?.user_data);
            localStorage.setItem("token", LoginRes?.data?.auth_token_data?.access_token);
            localStorage.setItem("userType", LoginRes?.data?.user_data?.user_type);
            message.destroy();
            message.success(LoginRes?.message);
            Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
            Gleap.identify(`user_${LoginRes?.data?.user_data?.id}`, {
                name: LoginRes?.data?.user_data?.name,
                email: LoginRes?.data?.user_data?.email,
            }, LoginRes?.data?.auth_token_data?.access_token);
            if (LoginRes?.data?.user_data?.user_type === 1)
                initialCall()
            setTimeout(() => {
                setRedirect(true);
            }, 300);
            fakeActionAuth();
        } else if (LoginRes?.status === false) {
            setLoading(false);
            message.destroy();
            message.warning(LoginRes?.message);
            fakeActionAuth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoginRes]);

    const submit = () => {
        if (userData.email && userData.password) {
            setLoading(true);
            let body = {
                username: userData.email,
                password: userData.password,
            };
            loginAction(body);
        } else {
            message.destroy();
            message.warning("please fill inputs");
        }
    };

    if (redirect || localStorage.getItem("token")) {
        return <Redirect to="/" />;
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column flex-root" style={{ height: '100%' }}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-light">
                        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                            <div className="w-lg-500px p-10 ">
                                <div className="text-center mb-11 ">
                                    <Link to='/'>
                                        <img alt="Logo" src="/assets/media/logo-bookprep.png" className="h-60px h-lg-75px shimmer" />
                                    </Link>
                                </div>
                                <div className="form w-100">
                                    <div className="text-center mb-11">
                                        <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
                                        <div className="text-gray-500 fw-semibold fs-6">Let us help you take your book arbitrage business<br /> to the next level</div>
                                    </div>
                                    <div className="fv-row mb-8">
                                        <input
                                            type="text"
                                            value={userData?.email}
                                            onChange={(e) => {
                                                setUserData({
                                                    ...userData,
                                                    email: e.target.value,
                                                });
                                            }}
                                            placeholder="Email"
                                            name="email"
                                            autoComplete="off"
                                            className="form-control h-60px ps-5 bg-transparent"
                                        />
                                    </div>
                                    <div className="fv-row mb-3">
                                        <input
                                            value={userData?.password}
                                            onChange={(e) => {
                                                setUserData({
                                                    ...userData,
                                                    password: e.target.value,
                                                });
                                            }}
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            autoComplete="off"
                                            className="form-control h-60px ps-5 bg-transparent"
                                        />
                                    </div>
                                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                        <div />
                                        <span className="text-info fw-bold">Forgot Password ?</span>
                                    </div>
                                    <div className="d-grid mb-10">
                                        <button
                                            disabled={loading}
                                            onClick={() => submit()}
                                            className="btn btn-primary h-50px"
                                        >
                                            {loading ? (
                                                <span className="">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                </span>
                                            ) : (
                                                <span className="indicator-label">Sign In</span>
                                            )}
                                        </button>
                                    </div>
                                    <div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
                                        <span className="text-info fw-bold"> Sign up</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="w-lg-500px d-flex flex-stack px-10 mx-auto">
                            <div className="me-10">
                                <button className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
                                    <img data-kt-element="current-lang-flag" className="w-20px h-20px rounded me-3" src="/assets/media/flags/united-states.svg" alt="" />
                                    <span data-kt-element="current-lang-name" className="me-1">English</span>
                                    <i className="ki-outline ki-down fs-5 text-muted rotate-180 m-0" />
                                </button>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" data-kt-menu="true" id="kt_auth_lang_menu">
                                    <div className="menu-item px-3">
                                        <span className="menu-link d-flex px-5" data-kt-lang="English">
                                            <span className="symbol symbol-20px me-4">
                                                <img data-kt-element="lang-flag" className="rounded-1" src="/assets/media/flags/united-states.svg" alt="" />
                                            </span>
                                            <span data-kt-element="lang-name">English</span>
                                        </span>
                                    </div>
                                    <div className="menu-item px-3">
                                        <span className="menu-link d-flex px-5" data-kt-lang="Spanish">
                                            <span className="symbol symbol-20px me-4">
                                                <img data-kt-element="lang-flag" className="rounded-1" src="/assets/media/flags/spain.svg" alt="" />
                                            </span>
                                            <span data-kt-element="lang-name">Spanish</span>
                                        </span>
                                    </div>
                                    <div className="menu-item px-3">
                                        <span className="menu-link d-flex px-5" data-kt-lang="German">
                                            <span className="symbol symbol-20px me-4">
                                                <img data-kt-element="lang-flag" className="rounded-1" src="/assets/media/flags/germany.svg" alt="" />
                                            </span>
                                            <span data-kt-element="lang-name">German</span>
                                        </span>
                                    </div>
                                    <div className="menu-item px-3">
                                        <span className="menu-link d-flex px-5" data-kt-lang="Japanese">
                                            <span className="symbol symbol-20px me-4">
                                                <img data-kt-element="lang-flag" className="rounded-1" src="/assets/media/flags/japan.svg" alt="" />
                                            </span>
                                            <span data-kt-element="lang-name">Japanese</span>
                                        </span>
                                    </div>
                                    <div className="menu-item px-3">
                                        <span className="menu-link d-flex px-5" data-kt-lang="French">
                                            <span className="symbol symbol-20px me-4">
                                                <img data-kt-element="lang-flag" className="rounded-1" src="/assets/media/flags/france.svg" alt="" />
                                            </span>
                                            <span data-kt-element="lang-name">French</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex fw-semibold text-primary fs-base gap-5">
                                <Link to='/' target="_blank">Terms</Link>
                                <Link to='/' target="_blank">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 " style={{ background: 'url(/assets/media/2111.svg) #f36c383d', backgroundSize: '35%' }}>
                        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
                            <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="/assets/media/Visual data-cuate.svg" alt="" />
                            <h1 className="d-none d-lg-block text-info fs-2qx fw-bolder text-center mb-7">Easy Book Prep’s service is new and revolutionary!</h1>
                            <div className="d-none d-lg-block text-dark fs-4 text-center">I am very excited to share with you our cutting edge technology and services that will help take your business to the next level!<br />
                                We have already prepped thousands upon thousands of Books and counting with the very technology and systems that we are now making available to you.</div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}