import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
    const path = `login`;
    const responseType = "LOGIN";
    const body = payload;
    return axiosCall("post", path, responseType, body);
};

export const fakeActionAuth = () => async (dispatch) => {
    dispatch({ type: "FAKE_ACTION_AUTH" });
};
