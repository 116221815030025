import axios from "axios";
import { EndPoint } from '../../../config';

const instance = axios.create({
    baseURL: `${EndPoint}api/v1/`,
});

export default function axiosCall(method, url, responseType, data, headers) {
    return async (dispatch) => {
        const apiData = data
            ? {
                method,
                url,
                data,
                headers,
            }
            : { method, url, headers };
        instance(apiData)
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: `${responseType}_SUCCESS`,
                        updatePayload: response.data,
                    });
                } else {
                    dispatch({
                        type: `${responseType}_ERROR`,
                        updatePayload: response.data,
                    });
                }
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    localStorage.clear();
                    return window.location.assign("/login");
                }
                dispatch({
                    type: `${responseType}_ERROR`,
                    updatePayload: {
                        ...err.response,
                        message: err?.response?.message || "Something Went Wrong.",
                        status: false,
                    },
                });
            });
    };
}
