import { message } from "antd";
import Nprogress from "nprogress";
import React, { Component } from "react";
import "./progress.css";

export default function asyncComponent(DisplayComponent) {
    class AsyncFunc extends Component {
        constructor(props) {
            super(props);
            this.state = {
                mounted: false,
            };
            Nprogress.configure({ showSpinner: false });
            Nprogress.start();
        }

        async componentDidMount() {
            this.mounted = true;
            message.destroy();
            Nprogress.done();
            this.setState({
                mounted: true,
            });
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            const { mounted } = this.state;
            return (
                <div className="globalHeight">
                    <DisplayComponent {...this.props} />
                </div>
            );
        }
    }
    return AsyncFunc;
}
