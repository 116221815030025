import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "./core/asyncComponent";

import Login_ from "./containers/login";
import Page_ from "./modules/Page";

function AppRoute(props) {
    return (
        <BrowserRouter history={props.history}>
            <Suspense
                fallback={
                    <div className="overlayLoading">
                        <div className="loader"></div>
                    </div>
                }
            >
                <Switch>
                    <Route exact path="/login" component={asyncComponent(Login_)} />
                    <Route path="/" component={asyncComponent(Page_)} />
                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRoute;