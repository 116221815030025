export default (state, action) => {
    if (typeof state === "undefined") {
        return [];
    }
    switch (action.type) {
        case "GET_INVENTORY_ORDER_LIST_SUCCESS":
        case "GET_INVENTORY_ORDER_LIST_ERROR":
            return {
                ...state,
                GetInventoryOrderListResponse: action.updatePayload,
            };
        case "GET_INVENTORY_ORDER_MASTER_DATA_SUCCESS":
        case "GET_INVENTORY_ORDER_MASTER_DATA_ERROR":
            return {
                ...state,
                InventoryOrderMasterDataResponse: action.updatePayload,
            };
        case "ADD_INVENTORY_ORDER_SUCCESS":
        case "ADD_INVENTORY_ORDER_ERROR":
            return {
                ...state,
                AddInventoryOrderResponse: action.updatePayload,
            };
        case "UPDATE_INVENTORY_ORDER_SUCCESS":
        case "UPDATE_INVENTORY_ORDER_ERROR":
            return {
                ...state,
                UpdateInventoryOrderResponse: action.updatePayload,
            };
        case "GET_INVENTORY_ORDER_DETAILS_SUCCESS":
        case "GET_INVENTORY_ORDER_DETAILS_ERROR":
            return {
                ...state,
                InventoryOrderDetailResponse: action.updatePayload,
            };
        case "GET_ASIN_DATA_SUCCESS":
        case "GET_ASIN_DATA_ERROR":
            return {
                ...state,
                AsinDetailResponse: action.updatePayload,
            };
        case "GET_EVENT_STATUS_LIST_SUCCESS":
        case "GET_EVENT_STATUS_LIST_ERROR":
            return {
                ...state,
                GetEventStatusListResponse: action.updatePayload,
            };
        case "GET_EVENT_SUB_STATUS_LIST_SUCCESS":
        case "GET_EVENT_SUB_STATUS_LIST_ERROR":
            return {
                ...state,
                GetEventSubStatusListResponse: action.updatePayload,
            };
        case "GET_EVENT_LOGS_DETAILS_SUCCESS":
        case "GET_EVENT_LOGS_DETAILS_ERROR":
            return {
                ...state,
                GetEventLogsResponse: action.updatePayload,
            };
        case "ADD_TRACKING_DETAIL_SUCCESS":
        case "ADD_TRACKING_DETAIL_ERROR":
            return {
                ...state,
                AddTrackingDetailResponse: action.updatePayload,
            };
        case "GET_TRACKING_DETAIL_SUCCESS":
        case "GET_TRACKING_DETAIL_ERROR":
            return {
                ...state,
                GetTrackingDetailResponse: action.updatePayload,
            };
        case "GET_CARRIER_LIST_SUCCESS":
        case "GET_CARRIER_LIST_ERROR":
            return {
                ...state,
                GetCarrierListResponse: action.updatePayload,
            };
        // case "GET_KEYWORD_DETAILS_SUCCESS":
        // case "GET_KEYWORD_DETAILS_ERROR":
        //     return {
        //         ...state,
        //         GetKeywordDetailsResponse: action.updatePayload,
        //     };
        // case "EXPORT_KEYWORD_DATA_SUCCESS":
        // case "EXPORT_KEYWORD_DATA_ERROR":
        //     return {
        //         ...state,
        //         ExportKeywordDataResponse: action.updatePayload,
        //     };
        // case "GET_KEYWORD_ITEMS_DETAILS_SUCCESS":
        // case "GET_KEYWORD_ITEMS_DETAILS_ERROR":
        //     return {
        //         ...state,
        //         GetKeywordItemDataResponse: action.updatePayload,
        //     };
        case "FAKE_ACTION_INVENTORY_ORDER":
            return [];
        default:
            return state;
    }
};
