export const nameObject = {
    "/": {
        name: "Main Dashboard",
    },
    "/dashboard": {
        name: "Main Dashboard",
    },
    "/scan-deliveries": {
        name: "Scan Deliveries",
    },
    "/inventory-order": {
        name: "Inventory Orders",
    },
    "/admin-actions/pre-receiving-listings": {
        name: "Pre Receiving Listings",
        parent: "Admin Actions",
    },
    "/admin-actions/cron-manager": {
        name: "Cron Manager",
        parent: "Admin Actions",
    },
    "/admin-actions/cron-manager/add": {
        name: "Add Cron",
        sub_parent: "Cron Manager",
        sub_link: "/admin-actions/cron-manager",
        parent: "Admin Actions",
    },
    "/admin-actions/cron-manager/edit": {
        name: "Edit Cron",
        sub_parent: "Cron Manager",
        sub_link: "/admin-actions/cron-manager",
        parent: "Admin Actions",
    },
    "/inventory-order/view": {
        name: "Order View",
        sub_parent: "Inventory Orders",
        sub_link: "/inventory-order",
    },
    "/inventory-items": {
        name: "Inventory Items",
    },
    "/inventory-items/view": {
        name: "Inventory Item View",
        sub_parent: "Inventory Items",
        sub_link: "/inventory-items",
    },
    "/inventory-items/sent-to-fba": {
        name: "Send To FBA",
        sub_parent: "Inventory Items",
        sub_link: "/inventory-items",
    },
    "/inventory-items/status": {
        name: "Inventory Items Status",
        sub_parent: "Inventory Items",
        sub_link: "/inventory-items",
    },
    "/event": {
        name: "Event",
    },
    "/event/view": {
        name: "Event View",
        sub_parent: "Event",
        sub_link: "/event",
    },
    "/receive-items": {
        name: "Receive Items",
    },
    "/entities/client": {
        name: "Client",
        parent: "Entities",
    },
    "/entities/client/client-address": {
        name: "Client Address",
        sub_parent: "Client",
        sub_link: "/entities/client",
        parent: "Entities"
    },
    "/entities/client/details": {
        name: "Add Client Details",
        sub_parent: "Client",
        sub_link: "/entities/client",
        parent: "Entities"
    },
    "/entities/client/add-prep-center": {
        name: "Client Prep Center",
        sub_parent: "Client",
        sub_link: "/entities/client/details",
        parent: "Entities"
    },
    "/entities/client/edit-prep-center": {
        name: "Client Prep Center",
        sub_parent: "Client",
        sub_link: "/entities/client/details",
        parent: "Entities"
    },
    "/entities/client/view-prep-center": {
        name: "Client Prep Center",
        sub_parent: "Client",
        sub_link: "/entities/client/details",
        parent: "Entities"
    },
    "/entities/prep-staff": {
        name: "Prep Staff",
        parent: "Entities",
    },
    "/entities/vendor": {
        name: "Vendor",
        parent: "Entities",
    },
    "/entities/vendor/vendor-address": {
        name: "Vendor Address",
        sub_parent: "Vendor",
        sub_link: "/entities/vendor",
        parent: "Entities"
    },
    "/operation/shipping": {
        name: "Shipping",
        parent: "Operation",
    },
    "/operation/prep-batch/add": {
        name: "Add Prep Batch",
        sub_parent: "Prep Batch",
        sub_link: "/operation/prep-batch",
        parent: "Operation",
    },
    "/operation/prep-batch/edit": {
        name: "Edit Prep Batch",
        sub_parent: "Prep Batch",
        sub_link: "/operation/prep-batch",
        parent: "Operation",
    },
    "/operation/prep-batch/view": {
        name: "View Prep Batch",
        sub_parent: "Prep Batch",
        sub_link: "/operation/prep-batch",
        parent: "Operation",
    },
    "/operation/prep-batch/add-batch-items": {
        name: "Add Items To Batch",
        sub_parent: "Prep Batch",
        sub_link: "/operation/prep-batch",
        parent: "Operation",
    },
    "/operation/prep-batch/add-batch-items-shipment": {
        name: "Shipment Batch",
        sub_parent: "Add Items To Batch",
        sub_link: "/operation/prep-batch",
        parent: "Operation",
    },
    "/operation/prep-batch": {
        name: "Prep Batch",
        parent: "Operation",
    },
    "/operation/prep-center": {
        name: "Prep Center",
        parent: "Operation",
    },
    "/operation/prep-center/add": {
        name: "Add Prep Center",
        sub_parent: "Prep Center",
        parent: "Operation",
        sub_link: "/operation/prep-center",
    },
    "/operation/prep-center/edit": {
        name: "Edit Prep Center",
        sub_parent: "Prep Center",
        parent: "Operation",
        sub_link: "/operation/prep-center",
    },
    "/settings/profile": {
        name: "My Profile",
        parent: "Settings",
    },
    "/settings/billing": {
        name: "Billing",
        parent: "Settings",
    },
    "/settings/marketplace-credentials": {
        name: "Marketplace Credentials",
        parent: "Settings",
    },
    "/settings/help": {
        name: "Help",
        parent: "Settings",
    },
    "/application-logs/central-log": {
        name: "Central Log",
        parent: "Application Logs",
    },
    "/application-logs/system-error-log": {
        name: "System Error Log",
        parent: "Application Logs",
    },
    "/add-item-to-location": {
        name: "Add Item to Location",
    },
    "/location": {
        name: "Location",
    },
    "/location/add": {
        name: "Add Location",
        sub_parent: "Location",
        sub_link: "/location",
    },
    "/location/edit": {
        name: "Edit Location",
        sub_parent: "Location",
        sub_link: "/location",
    },
    "/scan-item": {
        name: "Scan Item",
    },
    "/scan-list": {
        name: "Scan List",
    },
    "/sources": {
        name: "Sources",
    },
    "/sources/add": {
        name: "Add Source",
        sub_parent: "Sources",
        sub_link: "/sources",
    },
    "/sources/edit": {
        name: "Edit Source",
        sub_parent: "Sources",
        sub_link: "/sources",
    },
    "/sources/view": {
        name: "View Source",
        sub_parent: "Sources",
        sub_link: "/sources",
    },
    "/fba-packing-summary-report": {
        name: "FBA Packing Summary Report",
    },
};